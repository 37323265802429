<template>
    <v-row id="active-informations" class="ma-0 pa-0">
        <v-col cols="6" class="col-lg-8 pl-0 py-4">
            <v-card elevation="2" tile width="100%" height="100%" class="pa-1 px-3">
                <v-skeleton-loader v-if="infoLoading"
                    v-bind="{class: 'skeleteon-loader'}"
                    type="list-item"
                ></v-skeleton-loader>
                <v-chip-group mobile-breakpoint="600" v-if="!infoLoading">
                    <v-chip class="phone-line rounded-0" :color="line < model.ACTIVE_PHONE ? (model.ACTIVE_PHONE === model.NO_OF_PHONE ? 'error' :'success') : ''"
                    label :disabled="line >= model.ACTIVE_PHONE" v-for="line in callCentreEmp" tile :key="line" />
                </v-chip-group>
            </v-card>
        </v-col>
        <v-col cols="6" class="col-lg-4 pa-0 py-4">
            <v-card elevation="2" tile width="100%" height="50px" class="pa-1 px-3 d-flex align-center justify-space-around">
                <div class="w-full" v-if="infoLoading">
                    <v-skeleton-loader
                        v-bind="{class: 'skeleteon-loader'}"
                        type="list-item"
                    ></v-skeleton-loader>
                </div>
                <div class="text-h6 font-weight-bold" v-if="!infoLoading">
                    Phone <span class="text-h5 primary--text font-weight-bold">{{model.ACTIVE_PHONE}}/{{model.NO_OF_PHONE}}</span>
                </div>
                <div class="text-h6 font-weight-bold" v-if="!infoLoading">
                    Web <span class="text-h5 primary--text font-weight-bold">{{model.NO_OF_WEB}}</span>
                </div>
            </v-card>
        </v-col> 
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
    data() {
        return {
            model : {
                NO_OF_PHONE: 0,
                NO_OF_WEB: 0,
                ACTIVE_PHONE: 0,
            },
            infoLoading: false
        }
    },
    computed: {
        callCentreEmp() {
            const totalPh = this.model.ACTIVE_PHONE + (this.model.NO_OF_PHONE - this.model.ACTIVE_PHONE);
            return _.times(totalPh);
        }
    },
    created() {
        this.getInfoData();
    },
    mounted() {
       this.wsOnActionUpdate();
    },
    methods: {
        ...mapActions(['getEmailOptionsData']),

        getInfoData() {
            this.infoLoading = true;
            const reqData = {
                name: 'search',
                value: {
                    option_name: ['NO_OF_PHONE', 'NO_OF_WEB', 'ACTIVE_PHONE'],
                }
            }
            this.getEmailOptionsData(reqData).then((response) => {
                this.model = {...this.model, ...response.data.data};
                this.infoLoading = false;
            }).catch((err) => this.infoLoading = false);
        },
        wsOnActionUpdate() {
            Echo.channel('NO_OF_PHONE').listen('.saved',(noOfPh) => {
                this.model.NO_OF_PHONE = noOfPh.data;
            });
            Echo.channel('NO_OF_WEB').listen('.saved',(noOfWeb) => {
                this.model.NO_OF_WEB = noOfWeb.data;
            });
            Echo.channel('ACTIVE_PHONE').listen('.saved',(activePhone) => {
                this.model.ACTIVE_PHONE = activePhone.data;
            });
        },

    },
    beforeDestroy() {
        Echo.leaveChannel('NO_OF_PHONE');
        Echo.leaveChannel('NO_OF_WEB');
        Echo.leaveChannel('ACTIVE_PHONE');
    }
}
</script>
